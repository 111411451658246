<!-- Crear nuevo archivo: FilterSection.vue -->
<template>
  <b-row>
    <!-- Filtros de fecha -->
    <b-col md="4">
      <b-form-group :label="$t('labels.datefrom')" class="required" rules="required">
        <flat-pickr
          v-model="dateFrom"
          @input="emitChange"
          class="form-control"
          :config="flatpickrConfig"
        />
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group :label="$t('labels.dateto')" rules="required">
        <flat-pickr
          v-model="dateTo"
          @input="emitChange"
          class="form-control"
          :config="flatpickrConfig"
        />
      </b-form-group>
    </b-col>

    <!-- Filtro de Productos -->
    <b-col md="4">
      <b-form-group :label="$t('labels.products')" rules="required">
        <v-select
          v-model="product"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="productsOptions"
          @option:selected="handleSelectUpdate({ deselected: false })"
          @option:deselected="handleSelectUpdate({ deselected: true })"
          :clearable="false"
          :loading="loadingProducts"
        />
      </b-form-group>
    </b-col>

    <!-- Filtro de Marcas -->
    <b-col md="4">
      <b-form-group :label="$t('labels.brands')" rules="required">
        <v-select
          v-model="brand"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="brandsOptions"
          :clearable="false"
          @input="onBrandChange"
          :reduce="(option) => option.name"
          :loading="loadingBrands"
        />
      </b-form-group>
    </b-col>

    <!-- Filtro de Juego (opcional) -->
    <b-col md="4" v-if="showGameFilter">
      <b-form-group :label="$t('game_name')">
        <v-select
          v-model="game"
          :options="games"
          @search="onSearch"
          :loading="loadingGames"
          :filterable="false"
          :clearable="true"
          label="gameName"
          @option:selected="selectGame"
        >
          <template slot="no-options">
            {{ $t("type_a_letter_to_start_the_search") }}
          </template>
          <template slot="option" slot-scope="option">
            <div class="d-center overflow-hidden">
              <div class="small lh-125 pb-1">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <strong class="text-gray-dark">{{ option.gameName }}</strong>
                </div>
                <span class="d-block">{{ option.productId }}</span>
              </div>
            </div>
          </template>

          <template #list-footer>
            <div v-if="games">
              <b-button
                variant="light"
                @click="prev()"
                :disabled="page <= 0"
                class="btn-prev w-50"
              >
                <feather-icon icon="ChevronLeftIcon" />
              </b-button>
              <b-button
                variant="light"
                :disabled="!games.length"
                class="btn-next w-50"
                @click="next()"
              >
                <feather-icon icon="ChevronRightIcon" />
              </b-button>
            </div>
          </template>
        </v-select>
      </b-form-group>
    </b-col>

    <b-col md="2" class="d-flex align-items-end">
      <b-button variant="primary" @click="emitChange" class="mb-1">
        {{ $t("buttons.search") }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormSelect } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import "vue-select/dist/vue-select.css";
import { mapActions } from "vuex";
import axios from "@/libs/axios";
import whitelabelStoreModule from "../../../pages/whitelabel/whitelabelStoreModule";
import store from "@/store";

export default {
  name: "FilterSection",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    flatPickr,
    vSelect,
  },
  props: {
    showGameFilter: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingGames: false,
      loadingProducts: false, // nuevo
      loadingBrands: false, // nuevo
      dateFrom: this.value.dateFrom,
      dateTo: this.value.dateTo,
      brand: this.value.brand,
      game: this.value.game,
      games: [],
      search: "",
      limit: 10,
      page: 0,
      timeOut: null,
      flatpickrConfig: {
        altInput: true,
        altFormat: "d-m-Y h:i K",
        enableTime: true,
        wrap: true,
        plugins: [
          new yearDropdownPlugin({
            yearStart: 100,
            yearEnd: 0,
          }),
        ],
      },
      product: null,
      productsOptions: [],
      brandsOptions: [],
    };
  },
  computed: {
    whitelabelBrands() {
      return this.$store.state.whitelabelCurrencyNabvar?.whitelabel?.brands || [];
    },
    brandOptions() {
      return [
        ...this.whitelabelBrands.map((brand) => ({
          value: brand.brandId,
          text: brand.name,
        })),
      ];
    },
    gamesOptions() {
      if (!this.brand) return [{ value: null, text: "Todos los juegos" }];
      return this.games || [];
    },
  },
  methods: {
    yearDropdownPlugin,
    async onBrandChange(brandId) {
      this.brand = brandId;
      this.game = null;
      this.emitChange();
    },
    emitChange() {
      this.$emit("input", {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        brand: this.brand,
        game: this.game?.gameId,
        product: this.product ? [this.product] : [],
      });
    },
    ...mapActions("game", ["getGamesLobby"]),

    onSearch(search) {
      if (search.length) {
        this.search = search;
        this.page = 0;
        if (this.timeOut) clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
          this.getGames();
        }, 500);
      }
    },

    async getGames() {
      try {
        this.loadingGames = true;

        const filterParams = {
          q: this.search.trim(),
          perPage: this.limit,
          sortBy: "gameName",
          sortDesc: false,
          page: this.page,
        };
        const res = await axios.post("/getGamesLobby", {}, { params: filterParams });
        this.games = res.data.lobbys.map((lobby) => {
          return { ...lobby.game, productId: lobby.productId };
        });
      } catch (error) {
        console.error("Error fetching games:", error);
      } finally {
        this.loadingGames = false;
      }
    },

    selectGame(game) {
      this.game = game;
      this.emitChange();
    },

    next() {
      this.page += 1;
      this.getGames();
    },

    prev() {
      this.page -= 1;
      this.getGames();
    },

    removeIdKeys(arr) {
      if (!Array.isArray(arr)) return [];
      return arr.map((obj) => {
        const newObj = { ...obj };
        delete newObj._id;
        delete newObj.id;
        if (newObj.product) delete newObj.product.product;
        return newObj;
      });
    },

    async getBrands() {
      this.loadingBrands = true;
      try {
        if (!this.product) {
          this.brandsOptions = [];
          return;
        }

        const selectedProductId = [this.product.productId];
        const clientId = this.$store.state.whitelabelCurrencyNabvar?.whitelabel?.client;

        if (!clientId) {
          throw new Error("Client ID not found");
        }

        const brandsGet = await this.$store.dispatch(
          "app-whitelabel/fetchBrandsByProducts",
          {
            idClient: clientId,
            products: selectedProductId,
          }
        );

        const data = brandsGet?.data || [];
        this.brandsOptions = this.removeIdKeys(data).map((item) => ({
          ...item,
        }));
      } catch (error) {
        console.error("Error fetching brands:", error);
        this.brandsOptions = [];
      } finally {
        this.loadingBrands = false;
      }
    },

    async getProductsByGatewayClientId() {
      this.loadingProducts = true;
      try {
        const gatewayClientId = this.$store.state.whitelabelCurrencyNabvar.whitelabel
          .client;
        const productObject = await this.$store.dispatch(
          "app-whitelabel/fetchProducts",
          gatewayClientId
        );
        this.productsOptions = productObject.data.map((element) => element.product);
      } catch (error) {
        console.log("Error fetching products:", error);
      } finally {
        this.loadingProducts = false;
      }
    },

    handleSelectUpdate({ deselected }) {
      if (!deselected) {
        this.getBrands();
      } else {
        this.brand = null;
        this.brandsOptions = [];
      }
    },

    updateBrands() {
      if (!this.product) {
        this.brand = null;
        this.brandsOptions = [];
        return;
      }
      // Keep only the brands whose product matches the selected product
      if (this.brand) {
        const isValidBrand = this.brandsOptions.some(
          (brand) => brand.product.productId === this.product.productId
        );
        if (!isValidBrand) {
          this.brand = null;
        }
      }
    },

    async initialize() {
      await this.getProductsByGatewayClientId();
      if (this.product) {
        await this.getBrands();
      }
    },
  },
  mounted() {
    const WL_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(WL_APP_STORE_MODULE_NAME)) store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    this.initialize();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.btn-prev,
.btn-next {
  border-radius: 0;
}

[rules="required"] .col-form-label::after {
  content: "*";
  color: #dc3545;
  margin-left: 4px;
}
</style>
