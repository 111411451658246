<template>
  <div>
    <b-card>
      <b-tabs v-model="activeTab">
        <!-- Tab Usuarios -->
        <b-tab :title="$t('users')">
          <FilterSectionProfit
            v-if="activeTab === 0"
            v-model="filterSelect"
            :show-game-filter="false"
            @input="handleFilterChange"
          />
          <b-row>
            <!-- Columna principal de datos -->
            <b-col md="10">
              <b-card>
                <b-overlay :show="loadingData" variant="transparent" no-wrap />
                <!-- Games Report Table -->
                <b-table
                  :items="processedItems"
                  :fields="fields"
                  responsive
                  show-empty
                  empty-text="Not enough data to display"
                  class="mb-2"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @sort-changed="onSort"
                >
                  <template #cell(wins)="data">
                    <span class="text-success">{{ formatCurrency(data.item.wins) }}</span>
                  </template>

                  <template #cell(lose)="data">
                    <span class="text-danger">{{ formatCurrency(data.item.lose) }}</span>
                  </template>

                  <template #cell(ratio)="data">
                    <span :class="data.item.ratio >= 0 ? 'text-success' : 'text-danger'">
                      {{ formatCurrency(data.item.ratio) }}
                    </span>
                  </template>
                </b-table>

                <!-- Pagination -->
                <div class="d-flex justify-content-between align-items-center">
                  <b-form-select
                    v-model="perPage"
                    :options="perPageOptions"
                    size="sm"
                    class="w-25"
                    @change="onPerPageChange"
                  />
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @change="onPageChange"
                  />
                </div>
              </b-card>
            </b-col>

            <!-- Columna del árbol jerárquico -->
            <b-col class="p-0" md="2">
              <b-modal
                no-body
                id="modal-hierachy"
                hide-footer
                title="Listado de agentes"
                v-if="width < 768"
              >
                <HierarchyUsers @selectUser="selectUserHandler" ref="HerarchiComponent" />
              </b-modal>
              <b-card no-body class="p-0 py-1" v-else>
                <HierarchyUsers @selectUser="selectUserHandler" />
              </b-card>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Tab Juegos -->
        <b-tab :title="$t('games')">
          <FilterSectionProfit
            v-if="activeTab === 1"
            v-model="filterSelect"
            :show-game-filter="true"
            @input="handleFilterChange"
          />
          <b-row>
            <!-- Columna principal de datos -->
            <b-col md="12">
              <b-card>
                <b-overlay :show="loadingData" variant="transparent" no-wrap />
                <!-- Games Report Table -->
                <b-table
                  :items="processedItems"
                  :fields="fields"
                  responsive
                  show-empty
                  empty-text="Not enough data to display"
                  class="mb-2"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @sort-changed="onSort"
                >
                  <template #cell(wins)="data">
                    <span class="text-success">{{ formatCurrency(data.item.wins) }}</span>
                  </template>

                  <template #cell(lose)="data">
                    <span class="text-danger">{{ formatCurrency(data.item.lose) }}</span>
                  </template>

                  <template #cell(ratio)="data">
                    <span :class="data.item.ratio >= 0 ? 'text-success' : 'text-danger'">
                      {{ formatCurrency(data.item.ratio) }}
                    </span>
                  </template>
                </b-table>

                <!-- Pagination -->
                <div class="d-flex justify-content-between align-items-center">
                  <b-form-select
                    v-model="perPage"
                    :options="perPageOptions"
                    size="sm"
                    class="w-25"
                    @change="onPerPageChange"
                  />
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    class="my-0"
                    @change="onPageChange"
                  />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BBadge,
  BModal,
  BFormGroup,
  BButton,
  BPagination,
  BFormSelect,
} from "bootstrap-vue";
import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import flatPickr from "vue-flatpickr-component";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import FilterSectionProfit from "./FilterSectionProfit.vue";

export default {
  name: "GamesReport",
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BBadge,
    BModal,
    BFormGroup,
    BButton,
    BPagination,
    BFormSelect,
    HierarchyUsers,
    flatPickr,
    FilterSectionProfit,
  },
  data() {
    return {
      loadingData: false,
      reportItems: [],
      selectedUserId: null,
      selectedBrand: null,
      activeTab: 0, // users o games
      games: [
        { value: null, text: "Todos los juegos" },
      ],
      width: window.innerWidth,
      filterSelect: {
        dateFrom: moment()
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        brand: null,
        game: null,
      },
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      sortBy: "lose",
      sortDesc: true,
      perPageOptions: [10, 25, 50, 100],
    };
  },
  watch: {
    selectedBrand() {
      this.showData({ id: this.selectedUserId });
    },
    activeTab() {
      this.reportItems = [];
      // limpiar los filtros
      this.filterSelect = {
        dateFrom: moment()
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        brand: null,
        game: null,
      };
      this.showData({ id: this.selectedUserId });
    },
  },
  computed: {
    currency() {
      return this.$store.state.whitelabelCurrencyNabvar?.currency || "USD";
    },
    whitelabelId() {
      return this.$store.state.whitelabelCurrencyNabvar?.whitelabel?._id;
    },
    whitelabelBrands() {
      return this.$store.state.whitelabelCurrencyNabvar?.whitelabel?.brands || [];
    },
    brandOptions() {
      return [
        { value: null, text: "Todas las brands" },
        ...this.whitelabelBrands.map((brand) => ({
          value: brand._id,
          text: brand.name,
        })),
      ];
    },
    fields() {
      const commonFields = [
        { key: "loseCount", label: this.$t("bets"), sortable: true },
        { key: "lose", label: this.$t("bets_amount"), sortable: true },
        { key: "winsCount", label: this.$t("prizes"), sortable: true },
        { key: "wins", label: this.$t("prizes_amount"), sortable: true },
        { key: "ratio", label: this.$t("profit"), sortable: true },
      ];

      if (this.activeTab === 0) {
        return [{ key: "userName", label: this.$t("user"), sortable: true }, ...commonFields];
      } else {
        return [{ key: "gameName", label: this.$t("game"), sortable: true }, ...commonFields];
      }
    },
    processedItems() {
      return this.reportItems.map((item) => ({
        ...item,
        userName: this.activeTab === 0 ? item.user?.userName : undefined,
        gameName: this.activeTab === 1 ? item.game?.game?.gameName : undefined,
        wins: item.wins,
        winsCount: item.winsCount,
        lose: item.lose,
        loseCount: item.loseCount,
        played: item.played,
        ratio: item.ratio,
      }));
    },
  },
  methods: {
    yearDropdownPlugin,

    selectUserHandler({id}) {
      this.selectedUserId = id;
      this.showData({ id });
    },

    handleFilterChange(newFilters) {
      this.filterSelect = newFilters;
      this.showData({ id: this.selectedUserId });
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: this.currency,
      }).format(value);
    },

    onPageChange(page) {
      this.currentPage = page;
      this.showData({ id: this.selectedUserId });
    },

    onPerPageChange(value) {
      this.perPage = value;
      this.currentPage = 1;
      this.showData({ id: this.selectedUserId });
    },

    onSort(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.showData({ id: this.selectedUserId });
    },

    async showData({ id }) {
      // si el id es null, no se puede hacer la consulta y mostrar un toast
      if(!id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.activeTab === 0 ? "Seleccione un usuario" : "Seleccione un juego",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }
      if (!id || !this.whitelabelId || !this.filterSelect.brand) return;

      this.selectedUserId = id;

      try {
        this.loadingData = true;
        const endpoint =
          this.activeTab === 0 ? "/games/reportByUser" : "/games/reportByGameId";
        const response = await axios.get(endpoint, {
          params: {
            id: this.activeTab === 0 ? id : this.filterSelect.game,
            currency: this.currency,
            whitelabel: this.whitelabelId,
            perPage: this.perPage,
            page: this.currentPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            dateStart: new Date(this.filterSelect.dateFrom).toISOString(),
            dateEnd: new Date(this.filterSelect.dateTo).toISOString(),
            brandId: this.filterSelect.brand,
          },
        });

        if (response.data) {
          this.reportItems = response.data.data;
          this.totalRows = response.data.total;
        } else {
          this.reportItems = [];
          this.totalRows = 0;
        }
      } catch (error) {
        console.error("Error loading report:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error al cargar el reporte",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  destroyed() {
    window.removeEventListener("resize", () => {});
  },
};
</script>

<style scoped>
.scroll {
  max-height: 500px;
  overflow-y: auto;
}
</style>
